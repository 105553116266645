import {
    FETCH_CATEGORIES,
    ADD_CATEGORY,
    EDIT_CATEGORY,
} from '../constants'
const INIT_STATE = [];

const categoryReducer = (state=INIT_STATE,action)=>{
    switch(action.type){
        case FETCH_CATEGORIES:
            return [...state,...action.payload];
        case ADD_CATEGORY:
            return [...action.payload,...state];
        case EDIT_CATEGORY:
            return state.map((obj)=>{
                if(obj.is_default && action.payload.is_default) obj.is_default=0;
                if(obj.category_id===action.payload.category_id) return action.payload;
                return obj;
            })
        default:
            return state;
    }
}
export default categoryReducer;