import { CLEAN_RAW_PLAYLIST,BULK_REMOVE_ROW_PLAYLIST, EDIT_RAW_PLAYLIST_SINGLE_VIDEO_CATEGORY, FETCH_ROW_PLAYLIST, UPDATE_CATEGORY_ROW_PLAYLIST } from '../constants';
const INIT_STATE = {
    fullLoad: 0,
    data: [],
    msg:null,
    isFilter:false
};

const rowPlaylistReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_ROW_PLAYLIST:
            return { ...state, fullLoad: action.payload.fullLoad || state.fullLoad, data: [...state.data, ...action.payload.data],msg:action.payload.msg,isFilter:action.payload.isFilter }
        case UPDATE_CATEGORY_ROW_PLAYLIST:
            action.payload.index.map(i => {
                if (i !== -1) {
                    action.payload.categories_id.map((id, index) => {
                        if (!state.data[i].categories) state.data[i].categories = "[]"
                        if (state.data[i].categories.search(id) === -1) {
                            let intoJSON = JSON.parse(state.data[i].categories);
                            intoJSON.push({ category_id: id, category_name: action.payload.category_names[index] })
                            let intoString = JSON.stringify(intoJSON);
                            state.data[i].categories = intoString;
                        }
                    });
                }
            })
            return { ...state };
        case EDIT_RAW_PLAYLIST_SINGLE_VIDEO_CATEGORY:
            state.data[action.payload.index].categories = JSON.stringify(action.payload.categories);
            state.data[action.payload.index].final_video_page_id = action.payload.final_video_page_id; 
            return {...state};
        case BULK_REMOVE_ROW_PLAYLIST:
            action.payload.forEach(link => {
                state.data = state.data.filter(obj => obj.youtube_link !== link);
            })
            return { ...state };
        case CLEAN_RAW_PLAYLIST:
            return INIT_STATE;
        default:
            return state;
    }
}
export default rowPlaylistReducer;