import { FETCH_CONFIGURATIONS} from '../constants';
const INIT_STATE = {};

const configReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_CONFIGURATIONS:
            return {...action.payload};
        default:
            return state;
    }
}

export default configReducer;