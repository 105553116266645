import { useState, createContext } from "react";
const context = createContext();
const AlertMsg = (props)=>{
    const [alert, setAlert] = useState({ tag: null, msg: null })
    const [timeoutID,setTimeoutID]=useState(0);
    const updateAlert = (tag, msg) => {
        // console.log(timeoutID);
        clearTimeout(timeoutID);
        setAlert({ tag: tag, msg: msg });
        setTimeoutID(()=>setTimeout(() => {
            setAlert({ tag: null, msg: null });
        }, 3850));
    }
    return (
        <context.Provider value={{ alert, updateAlert}}>
            {props.children}
        </context.Provider>
    )
}

export default AlertMsg;
export { context };