const BASE_URL = process.env.REACT_APP_BACKEND_SERVER;
export const Config = {
        // user
        login: BASE_URL + "/login",
        verifyToken: BASE_URL + "/verify-token",
        createUser:BASE_URL+'/api/register-user',
        updateUser:BASE_URL+'/api/update-user',
        fetchUser:BASE_URL+'/api/user',
        listAllUserMembers:BASE_URL+'/list-user-members',
        fetchUserMemberFields:BASE_URL+'/user-member',
        // Artist
        editArtistBio: BASE_URL + '/edit-artist-bio',
        fetchArtistBio: BASE_URL + '/artist-bio',
        channelIDs: BASE_URL + "/channelIDs",
        fetchArtistDetails: BASE_URL + "/artist-detail",
        updateArtistDetails: BASE_URL + "/update-artist-detail",
        addArtist: BASE_URL + "/add/channelIDs",
        refreshData: BASE_URL + "/refresh/channelDetails",
        // categories
        fetchCategories: BASE_URL + "/all-categories",
        addCategory: BASE_URL + '/add-category',
        editCategory: BASE_URL + '/edit-category',
        // playlist
        channelDetails: BASE_URL + "/playlist",
        updateProductPage: BASE_URL + '/update-product-page-details',
        removeVideos: BASE_URL + '/remove-videos',
        addBulkCategories: BASE_URL + '/set-bulk-categories',
        rowPlaylist: BASE_URL + '/row-playlist',
        // search api
        autoComplete: BASE_URL + '/api/autocomplete',
        backOfficeSearchApi: BASE_URL + '/search',
        getVideoById:BASE_URL+'/get-video-by-id',
        // cron api
        getCronApiKey: BASE_URL + '/api/p/get-cron-api-key',
        regenerateCronApiKey: BASE_URL + '/api/p/regenerate-api-key',
        activateCronApi: BASE_URL + '/api/p/cron-api',
        // lyrics
        fetchLyricsFromURL:BASE_URL+'/fetch-lyrics',
        // configurations
        fetchConfigurations:BASE_URL+'/configurations',
        setConfigurations:BASE_URL+'/set-configurations',
        // Menu
        saveMenu:BASE_URL+'/save-menu',
}
