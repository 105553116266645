export const common_routes = {
    home:"/",
    dashboard:'/dashboard',
    productDetailPage:'/details',
    allArtists:'/all-artist',
    artistDetail:'/artist-detail',
    artistBio:'/artist-bio',
    artistPlaylist:'/artist-playlist',
    categories:'/category',
    addCategory:'/add-category',
    editCategory:'/edit-category',
    updateUserInfo:'/update-user-info',
}

export const userRoleBaseRoutes = {
    ADMIN:{
        ...common_routes,
        cronApi:'/cron-api',
        config:'/config',
        addUser:'/member/add-user',
        membersList:'/member/list-user-members',
        editUserMember:'/edit-user-member',
        createMenu:'/content-tool/create-content'
    },
    MANAGER:{
        ...common_routes
    },
}
