import {UPDATE_USER_INFO_LOGIN,LOGOUT_USER,UPDATE_USER_INFO_SESSION, UPDATE_ARTIST_NAME, UPDATE_USER_NAME} from "../constants";

const INIT_STATE = {
    fname:null,
    lname:null,
    login:false,
    role:null,
    email:null,
};

const userReducer = (state=INIT_STATE,action)=>{
    switch(action.type){
        case UPDATE_USER_INFO_LOGIN:
            return {
                ...state,
                fname:action.payload.firstName,
                lname:action.payload.lastName,
                login:true,
                role:action.payload.role,
                email:action.payload.email
            }
        case UPDATE_USER_INFO_SESSION:
            return{
                ...state,
                fname:action.payload.firstName,
                lname:action.payload.lastName,
                login:true,
                email:action.payload.email,
                role:action.payload.role
            }
        case LOGOUT_USER:
            return INIT_STATE;
        case UPDATE_USER_NAME:
            return {...state,fname:action.payload.firstName,
                lname:action.payload.lastName};
        default:
            return state;
    }
}

export default userReducer;