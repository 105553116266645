import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logout_user, userSession } from "../redux/user/UserAction";
import { context } from "../context/AlertContext";
import { fetchConfiguration } from "../redux/configurations/configAction";
import { userRoleBaseRoutes } from "./UserRoleList";
import Spinner from "../views/spinner/Spinner";

export const ProtectedRoute = (props) => {
  const alert = useContext(context);
  const [loader,setloader] = useState(true);
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
 
  if (!user.login) {
    userSession().then(async (res) => {
      if (res.success) {
        dispatch(res);
        let newRes = await fetchConfiguration();
        if(newRes.success){
          newRes.payload.forEach((element)=>{
            dispatch({type:newRes.type,payload:{[element.config_key]:element.config_value}});
          })
        }
      }
      else {
        dispatch(logout_user());
        navigate('/auth/login');
      }
      if (res.message) alert.updateAlert(res.success, res.message);
      setloader(false);
    }).catch((_) => {
      console.log(_);
      navigate('/auth/login');
      setloader(false);
    })
  }
  useEffect(()=>{
    if (user.role && !Object.values(userRoleBaseRoutes[user.role]).find(href=>href===location.pathname)) {
      setloader(false);
      navigate('404');
    }
  },[user.role])
  useEffect(()=>{
    if(user.login){
      setloader(false);
    }
  },[user.login])
  return <>
  {loader?<Spinner/>:props.children}
  </>;
};
