import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import { ProtectedRoute } from './ProtectedRoute';
import { common_routes, userRoleBaseRoutes } from './UserRoleList';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

// authenticated
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));

// pages
// --playlist
const Dashboard_main = Loadable(lazy(()=> import('../views/pages/playlist/RawPlaylist')));
const Playlist = Loadable(lazy(()=> import('../views/pages/playlist/Playlist')));
const DetailedPage = Loadable(lazy(()=> import('../views/pages/playlist/DetailedPage')));
// --artist
const AllArtist = Loadable(lazy(() => import('../views/pages/artist/Artist')));
const ArtistBio = Loadable(lazy(()=> import('../views/pages/artist/Bio')));
const ArtistDetailedPage = Loadable(lazy(()=> import('../views/pages/artist/ArtistDetail')));
// --category
const Categories = Loadable(lazy(()=> import('../views/pages/category/Categories')));
const AddCategories = Loadable(lazy(()=> import('../views/pages/category/Add')));
const EditCategories = Loadable(lazy(()=> import('../views/pages/category/Edit')));
// Role Base Authenticated Pages
const Cron = Loadable(lazy(()=> import('../views/pages/Cron')));
const UpdateUserInfo = Loadable(lazy(()=> import('../views/pages/UpdateUserInfo')));
const Configuration = Loadable(lazy(()=> import('../views/pages/Configuration')));
// --Members pages
const AddUser = Loadable(lazy(()=> import('../views/pages/members/AddUser')));
const ListUser = Loadable(lazy(()=> import('../views/pages/members/ListUser')));
const EditUserMember = Loadable(lazy(()=> import('../views/pages/members/EditUserMember')));
// --Dynamic Content
const CreateContent = Loadable(lazy(()=> import('../views/pages/pageContent/CreateContent')));


/* ****Routes***** */

const Router = [
  {
    path: '/',
    element: (
    <ProtectedRoute>
      <FullLayout />
    </ProtectedRoute>
    ),
    children: [
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: common_routes.dashboard, exact: true, element: <Dashboard_main /> },
      {path:common_routes.productDetailPage,exact:true,element:<DetailedPage/>},
      // Artist
      {path:common_routes.allArtists,exact:true,element:<AllArtist/>},
      {path:common_routes.artistDetail,exact:true,element:<ArtistDetailedPage/>},
      {path:common_routes.artistBio,exact:true,element:<ArtistBio/>},
      // playlist
      {path:common_routes.artistPlaylist,exact:true,element:<Playlist/>},
      // category
      {path:common_routes.categories,exact:true,element:<Categories/>},
      {path:common_routes.addCategory,exact:true,element:<AddCategories/>},
      {path:common_routes.editCategory,exact:true,element:<EditCategories/>},
      // memebers
      {path:common_routes.updateUserInfo,exact:true,element:<UpdateUserInfo/>},
      { path: '*', element: <Navigate to="/auth/404" /> },
      // role base page
      {path:userRoleBaseRoutes.ADMIN.addUser,exact:true,element:<AddUser/>},
      {path:userRoleBaseRoutes.ADMIN.membersList,exact:true,element:<ListUser/>},
      {path:userRoleBaseRoutes.ADMIN.editUserMember,exact:true,element:<EditUserMember/>},
      {path:userRoleBaseRoutes.ADMIN.cronApi,exact:true,element:<Cron/>},
      {path:userRoleBaseRoutes.ADMIN.config,exact:true,element:<Configuration/>},
      // Dynamic Content
      {path:userRoleBaseRoutes.ADMIN.createMenu,exact:true,element:<CreateContent/>},
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
