import {
    FETCH_ARTISTS,
    ADD_ARTIST,
    REMOVE_CHANNEL_ID,
    UPDATE_ARTIST_NAME,
} from '../constants'

const INT_STATE = [];
export const artistReducer = (state = INT_STATE, action) => {
    switch (action.type) {
        case FETCH_ARTISTS:
            return [
                ...state,
                ...action.payload
            ]
        case ADD_ARTIST:
            return [
                ...state,
                action.payload
            ]
        case UPDATE_ARTIST_NAME:
            return [...state.map(obj=>{
                if(obj.channel_id===action.channel_id){
                    obj.artist_name=action.artist_name;
                }
                return obj;
            })]
        default:
            return state;    }
}

export default artistReducer;