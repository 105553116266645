import {
    ALL_PLAYLIST,
    UPDATE_PRODUCT_DETAILS,
    REMOVE_VIDEOS,
    UPDATE_BULK_CATEGORIES_FOR_ARTIST,
    EDIT_PRODUCT_PAGE_CATEGORY,
} from '../constants'

const INT_STATE = {};
const playlistReducer = (state = INT_STATE, action) => {
    switch (action.type) {
        case ALL_PLAYLIST:
            return {
                ...state,
                [action.payload.artist_id]: action.payload.data
            }

        case UPDATE_PRODUCT_DETAILS:
            let newObj = {};
            let arrObj = Object.keys(state).map((ele) => {
                ele = parseInt(ele);
                if (ele === action.payload.artist_id) {
                    let currentObj = state[ele][action.payload.index];
                    currentObj = { ...currentObj, ...action.payload };
                    delete currentObj.index;
                    state[ele][action.payload.index] = currentObj;
                }
                return { [ele]: state[ele] };
            });
            arrObj.forEach((ele) => {
                newObj = { ...newObj, ...ele };
            })
            return newObj;

        case EDIT_PRODUCT_PAGE_CATEGORY:
            if (state.hasOwnProperty(action.payload.artist_id))
                state[action.payload.artist_id][action.payload.index].categories = JSON.stringify(action.payload.categories)
            return { ...state };

        case REMOVE_VIDEOS:
            action.payload.artist_id.forEach(artistId => {
                if (state.hasOwnProperty(artistId)) {
                    let playlist = state[artistId];
                    action.payload.data.forEach(link => {
                        let newplaylist = playlist.filter(item => item.youtube_link !== link);
                        playlist = newplaylist.length ? newplaylist : playlist.filter(item => item.youtube_link === link).length ? newplaylist : playlist;

                    });
                    state[artistId] = [...playlist];
                }
            })
            return { ...state };

        case UPDATE_BULK_CATEGORIES_FOR_ARTIST:
            let artistIdArr = Object.keys(action.payload);
            artistIdArr.forEach(artistId => {
                if (state.hasOwnProperty(artistId)) {
                    action.payload[artistId].videoIds.forEach(videoId => {
                        state[artistId] = state[artistId].map(obj => {
                            if (obj.final_video_page_id === videoId) {
                                action.payload[artistId].categories.forEach(catObj => {
                                    if (!obj.categories) obj.categories = "[]"
                                    if (obj.categories.search(catObj.category_id) === -1) {
                                        let intoJSON = JSON.parse(obj.categories);
                                        intoJSON.push(catObj)
                                        let intoString = JSON.stringify(intoJSON);
                                        obj.categories = intoString;
                                    }
                                })
                            }
                            return obj;
                        })
                    })
                    return { ...state };
                }
                return state;
            })
        default:
            return state;
    }
}

export default playlistReducer;