// Layout and colors CONSTANTS
export const LIGHT_THEME = 'LIGHT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const THEME_COLOR = 'THEME_COLOR';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const DIRECTION = 'DIRECTION';
export const BLUE_THEME = 'BLUE_THEME';
export const GREEN_THEME = 'GREEN_THEME';
export const RED_THEME = 'RED_THEME';
export const BLACK_THEME = 'BLACK_THEME';
export const PURPLE_THEME = 'PURPLE_THEME';
export const INDIGO_THEME = 'INDIGO_THEME';
export const ORANGE_THEME = 'ORANGE_THEME';
// CHAT CONSTANTS
export const FETCH_CHAT_SUCCESS = 'FETCH_CHAT_SUCCESS';
export const SELECTED_CHAT = 'SELECTED_CHAT';
export const SEARCH_USER = 'SEARCH_USER';
export const MSG_SUBMIT = 'MSG_SUBMIT';

// EMAIL CONSTANTS
export const STAR_EMAIL = 'STAR_EMAIL';
export const IMPORTANT_EMAIL = 'IMPORTANT_EMAIL';
export const OPEN_EMAIL = 'OPEN_EMAIL';
export const TRASH_EMAIL = 'TRASH_EMAIL';
export const ASSIGN_FOLDER = 'ASSIGN_FOLDER';
export const ASSIGN_LABEL = 'ASSIGN_LABEL';
export const SET_SELECTED_EMAIL = 'SET_SELECTED_EMAIL';
export const FILTER_EMAIL = 'FILTER_EMAIL';
export const SET_EMAIL_VISIBILITY_FILTER = 'SET_EMAIL_VISIBILITY_FILTER';

// USER CONSTANT
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER_INFO_LOGIN = 'UPDATE_USER_INFO_LOGIN';
export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
export const UPDATE_USER_INFO_SESSION='UPDATE_USER_INFO_SESSION'
export const LOGOUT_USER='LOGOUT_USER';

// Youtube store----------------------------------------------->
export const REMOVE_CHANNEL_ID = 'REMOVE_CHANNEL_ID';
// Playlist
export const ALL_PLAYLIST = 'ALL_PLAYLIST';
export const UPDATE_PRODUCT_DETAILS = 'UPDATE_PRODUCT_DETAILS';
export const REMOVE_VIDEOS = 'REMOVE_VIDEOS';
export const UPDATE_BULK_CATEGORIES_FOR_ARTIST = 'UPDATE_BULK_CATEGORIES_FOR_ARTIST';
export const EDIT_PRODUCT_PAGE_CATEGORY = 'EDIT_PRODUCT_PAGE_CATEGORY';

// raw playlist
export const FETCH_ROW_PLAYLIST = 'FETCH_ROW_PLAYLIST';
export const UPDATE_CATEGORY_ROW_PLAYLIST = 'UPDATE_CATEGORY_ROW_PLAYLIST';
export const BULK_REMOVE_ROW_PLAYLIST = 'BULK_REMOVE_ROW_PLAYLIST';
export const EDIT_RAW_PLAYLIST_SINGLE_VIDEO_CATEGORY = 'EDIT_RAW_PLAYLIST_SINGLE_VIDEO_CATEGORY';
export const CLEAN_RAW_PLAYLIST = 'CLEAN_RAW_PLAYLIST';


// Category
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
// Artist
export const STORE_ALL_ARTIST_DETAILS = 'STORE_ALL_ARTIST_DETAILS';
export const FETCH_ARTISTS = 'FETCH_ARTISTS';
export const ADD_ARTIST = 'ADD_ARTIST';
export const UPDATE_ARTIST_NAME = 'UPDATE_ARTIST_NAME';

// search
export const STORE_SEARCH_DATA = 'STORE_SEARCH_DATA';
export const RESET_SEARCH_DATA = 'RESET_SEARCH_DATA';

// configurations
export const FETCH_CONFIGURATIONS = 'FETCH_CONFIGURATIONS';
