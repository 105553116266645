import { Config } from "../../api_services"
import serverReq from "../../helper/serverReq"
import { FETCH_CONFIGURATIONS } from "../constants";
import responser from "../responser";

export const fetchConfiguration = async()=>{
    let res = await serverReq('GET',Config.fetchConfigurations,null,'private');
    return responser(res,FETCH_CONFIGURATIONS);
}

export const setConfiguration = async(data)=>{
    let res = await serverReq('POST',Config.setConfigurations,data,'private');
    return responser(res,FETCH_CONFIGURATIONS);
}