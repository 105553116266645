import { Config } from "../../api_services";
import serverReq from "../../helper/serverReq";
import { UPDATE_USER_INFO_LOGIN, LOGOUT_USER,UPDATE_USER_INFO_SESSION, UPDATE_ARTIST_NAME, UPDATE_USER_NAME } from "../constants";
import responser from "../responser";

export const userInfoLogin = async(data) => {
    let res = await serverReq('POST',Config.login,data);
    if(res.success) localStorage.setItem('access_token',res.data.token);
    return responser(res,UPDATE_USER_INFO_LOGIN);
}

export const userSession= async() => {
    let res = await serverReq('GET',Config.verifyToken,null,'private');
    return responser(res,UPDATE_USER_INFO_SESSION);
}

export const logout_user = () => {
    localStorage.removeItem('access_token');
    return { type: LOGOUT_USER }
}

export const createUser = async(data) => {
    let res = await serverReq('POST',Config.createUser,data,'private')
    return responser(res);
}

export const updateUser = async(data) => {
    let res = await serverReq('POST',Config.updateUser,data,'private')
    return responser(res);
}

export const fetchUser = async() => {
    let res = await serverReq('GET',Config.fetchUser,null,'private')
    return responser(res);
}

export const updateUserName = (data) => {
    return {type: UPDATE_USER_NAME,payload:data};
}
export const listAllUserMembers = async() => {
    let res = await serverReq('GET',Config.listAllUserMembers,null,'private');
    return responser(res);
}
export const fetchUserMemberFields = async(data) => {
    let res = await serverReq('GET',Config.fetchUserMemberFields,null,'private',data);
    return responser(res);
}