import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import userReducer from './user/UserReducer';
import configReducer from './configurations/configReducer';
import { 
  artistReducer,
  categoryReducer,
  playlistReducer,
  rowPlaylistReducer,
} from './youtube store';

const RootReducers = combineReducers({
  CustomizerReducer,
  userReducer,
  artistReducer,
  playlistReducer,
  categoryReducer,
  rowPlaylistReducer,
  configReducer,
});
export default RootReducers;
